import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
//API
import { apiPostPromotion } from "../../../../Services/Api_promotion"
//componentes
import { InputAutocompleteAsync } from "../../../Common/Inputs2"
//material ui
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { toast } from "react-toastify"
import TablaPromociones from "./TablaPromociones"
import { DataPerfiles, UseColaboradoresPromotions } from "./UsoMovimientos"

/**
 * Description placeholder
 *
 * @param {Object} param0
 * @param {int} param0.colaboradorId id del colaborador que se va a solicitar
 * @param {int} param0.reporta_a id del perfil de puesto que se va a solicitar
 * @param {function} param0.setOpen función para actualizar el estado de apertura del dialogo
 * @param {boolean} param0.open booleano para indicar si el dialogo se va a abrir o cerrar
 * @returns {JSX.Element} Elemento JSX que representa el componente ModalMovimientos
 */
const ModalMovimientos = ({ colaboradorId, reporta_a, setOpen, open }) => {
	const token = useSelector((state) => state.user.token)
	const [disabledButtons, setDisabledButtons] = useState(false)
	const [colaboradoresAll, setColaboradoresAll] = useState(false)

	// llamado a la api para obtener los perfiles de puesto y colaboradores
	const { colaboradores, load } = UseColaboradoresPromotions(
		colaboradorId,
		open,
		colaboradoresAll,
	)
	const { perfiles, loadPerfiles } = DataPerfiles(reporta_a)
	const [listMovimientos, setListMovimientos] = useState([])
	const [detail, setDetail] = useState([])
	const [colaboradoresEdit, setColaboradoresEdit] = useState([])
	const [select, setSelect] = useState({
		colaborador: null,
		perfilPuesto: null,
	})

	useEffect(() => {
		let noSelect = colaboradores.filter((colaborador) => {
			let isNotSelect = true
			listMovimientos.forEach((element) => {
				if (element.colaborador.id === colaborador.id) isNotSelect = false
			})
			return isNotSelect
		})
		setColaboradoresEdit(noSelect)
	}, [colaboradores, listMovimientos])

	let tableData = [
		{ label: "", id: "foto" },
		{ label: "No. Empleado", id: "no_empleado" },
		{ label: "Nombre", id: "full_name" },
		{ label: "Departamento", id: "departamento" },
		{ label: "Puesto", id: "puesto" },
		{ label: "Nuevo Puesto", id: "nombre" },
	]
	const createMovement = () => {
		if (select.colaborador && select.perfilPuesto) {
			if (select.colaborador?.perfil_puesto === select.perfilPuesto?.nombre) {
				setSelect({ ...select, perfilPuesto: null })
				toast.error("El perfil de puesto ya lo tiene el colaborador")
			} else {
				setListMovimientos([...listMovimientos, { ...select }])
				setDetail([
					...detail,
					{
						colaborador_id: select.colaborador.id,
						perfil_puesto_id: select.perfilPuesto.id,
					},
				])
				setSelect({ colaborador: null, perfilPuesto: null })
			}
		} else toast.error("Selecciona un colaborador y un perfil")
	}

	const sendMovement = async () => {
		setDisabledButtons(true)
		let { success } = await apiPostPromotion(token, {
			colaborador_id: colaboradorId,
			detail,
		})
		if (success) {
			setOpen(false)
			setDetail([])
			setSelect({ colaborador: null, perfilPuesto: null })
			setListMovimientos([])
		}
		setDisabledButtons(false)
	}

	return (
		<Dialog
			open={open}
			onClose={() => {
				setOpen(false)
				setSelect({ colaborador: null, perfilPuesto: null })
				setListMovimientos([])
				setColaboradoresAll(false)
			}}
			maxWidth={"lg"}
			sx={{ minHeight: 100 }}
		>
			<DialogContent sx={{ minHeight: 250 }}>
				<Grid
					container
					// alignItems="end"
					justifyContent={"center"}
					alignContent={"center"}
					spacing={3}
				>
					<Grid
						item
						xs={12}
					>
						<Typography
							variant="h6"
							component="div"
							sx={{ flexGrow: 1, textAlign: "center" }}
						>
							Solicitud de Movimientos de Personal
						</Typography>
					</Grid>
					<Grid
						item
						xs={4}
					>
						<InputAutocompleteAsync
							label={"Colaboradores"}
							options={colaboradoresEdit}
							optionsLabel="full_name"
							loading={load}
							value={select.colaborador}
							getOptionDisabled={(option) => option.promotion_in_progress}
							setValue={(e) => setSelect({ ...select, colaborador: e })}
						/>
						<FormGroup>
							<FormControlLabel
								control={<Checkbox />}
								label="Todos Colaboradores"
								disabled={colaboradoresAll}
								onChange={(event) => {
									if (event.target.checked) setColaboradoresAll(true)
								}}
							/>
						</FormGroup>
					</Grid>
					<Grid
						item
						xs={4}
					>
						<InputAutocompleteAsync
							label="Nuevo Perfil De Puesto"
							options={perfiles}
							optionsLabel="nombre"
							loading={loadPerfiles}
							value={select.perfilPuesto}
							setValue={(e) => setSelect({ ...select, perfilPuesto: e })}
						/>
					</Grid>
					<Grid
						xs={4}
						item
					>
						<Button
							disabled={disabledButtons}
							variant="contained"
							size="large"
							onClick={createMovement}
						>
							Crear movimiento
						</Button>
					</Grid>
					<Grid
						item
						xs={12}
					>
						<TablaPromociones
							tableData={tableData}
							listMovimientos={listMovimientos}
							hidden={listMovimientos.length === 0}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ textAlign: "right" }}
					>
						<Button
							disabled={disabledButtons}
							variant="contained"
							size="large"
							sx={{ ml: 1 }}
							onClick={sendMovement}
						>
							Enviar Solicitud
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalMovimientos
