import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { toast } from "react-toastify"

//mui
import CheckIcon from "@mui/icons-material/Check"
import EditIcon from "@mui/icons-material/Edit"
import {
	Autocomplete,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
} from "@mui/material"
//api
import { getPerfiles, getSucursales } from "../../Services/Api"
import { modificarDatosGeneralesColaborador } from "../../Services/ApiColaboradores"

import { InputAutocomplete } from "#common/input/InputAutocomplete"
import { Stack } from "@mui/material"
import { GridInputSelect } from "../Common/Inputs"
import { api_rh_get } from "#api/API_RH"
// import { Input } from '#common/Inputs2';

const ModalDatosGenerales = ({ open, setOpen, colaboradorData, reload }) => {
	const [disabledEdit, setDisabledEdit] = useState(false)
	const [disabledCheck, setDisabledCheck] = useState(true)
	const [editFields, setEditFields] = useState({
		perfil_puesto_id: false,
		jefe_directo_id: false,
		sucursal_id: false,
		correo: false,
		telefono_lubtrac: false,
	})
	const [listPerfiles, setListPerfiles] = useState([])
	const [listColabo, setListColabo] = useState([])
	const [listSucursales, setListSucursales] = useState([
		{ label: colaboradorData.sucursal, value: colaboradorData.sucursal_id },
	])
	const [jefe, setJefe] = useState("")
	const [perfil, setPerfil] = useState(null)
	const [sucursal, setSucursal] = useState(colaboradorData.sucursal_id)
	const [correo, setCorreo] = useState(colaboradorData.correo)
	const [tel, setTel] = useState(colaboradorData.telefono_lubtrac)
	const token = useSelector((state) => state.user.token)
	const params = useMemo(() => ({ only_name: true }), [])
	const enabledEdit = (field) => {
		setDisabledEdit(true)
		setEditFields({ ...editFields, [field]: true })
		if (field === "perfil_puesto_id") {
			getPerfiles(token)
				.then((response) => {
					setDisabledCheck(false)
					response.map((perfil) => (perfil.label = perfil.nombre))
					setListPerfiles(response)
				})
				.catch((error) => console.error(error))
		}
		if (field === "jefe_directo_id") {
			api_rh_get(`colaboradores`, {
				params,
				success: (response) => {
					setListColabo(response?.colaboradores)
					setDisabledCheck(false)
				},
			})
		}
		if (field === "sucursal_id") {
			setDisabledCheck(false)
			getSucursales(token).then((response) => {
				response.map((sucursal) => {
					sucursal.label = sucursal.nombre
					sucursal.value = sucursal.id
					return sucursal
				})
				setListSucursales(response)
				setEditFields({ ...editFields, sucursal_id: true })
			})
		}
		if (field === "correo") {
			setDisabledCheck(false)
		}
		if (field === "telefono_lubtrac") {
			setDisabledCheck(false)
		}
	}

	const saveData = async (field) => {
		let valor = ""
		let esCorreoPermitido = false
		let correo_permitido = [
			"@lubtrac.com.mx",
			"@lubtrac.com.gt",
			"@becoming.mx",
		]

		switch (field) {
			case "perfil_puesto_id":
				valor = perfil?.id
				break
			case "jefe_directo_id":
				valor = jefe?.id
				break
			case "sucursal_id":
				valor = sucursal
				break
			case "telefono_lubtrac":
				valor = tel
				break
			case "correo":
				valor = correo
				field = "email"
				esCorreoPermitido = correo_permitido.some((endStr) =>
					correo.endsWith(endStr),
				)

				if (!esCorreoPermitido) {
					field = "correo_personal"
				}

				break
			default:
				valor = null
				field = null
		}

		setDisabledCheck(true)
		if (valor)
			await toast.promise(
				modificarDatosGeneralesColaborador(token, colaboradorData.id, {
					[field]: valor,
				}),
				{
					pending: "Guardando datos...",
					success: {
						render({ data }) {
							reload()
							setOpen(false)
							return `Datos actualizados correctamente`
						},
					},
					error: "Error al actualizar los datos",
				},
			)
		setEditFields({ ...editFields, [field]: false })
		setDisabledEdit(false)
		setDisabledCheck(false)
	}

	if (!open) return <></>
	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			maxWidth={"sm"}
		>
			<DialogTitle sx={{ fontSize: 24, fontWeight: "bold", color: "#555" }}>
				Editar Datos Generales
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid
						item
						md={10}
						sx={{ mt: 1 }}
					>
						{editFields.perfil_puesto_id ? (
							<Autocomplete
								disablePortal
								id="autoPerfil"
								options={listPerfiles}
								sx={{ width: 400 }}
								value={colaboradorData.perfil}
								onChange={(event, value) => {
									setPerfil(value)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Perfil de puesto"
									/>
								)}
							/>
						) : (
							<TextField
								label="Perfil de puesto"
								variant="outlined"
								fullWidth
								disabled
								value={colaboradorData.perfil}
							/>
						)}
					</Grid>
					<Grid
						item
						md={2}
						sx={{ p: 1 }}
					>
						{editFields.perfil_puesto_id ? (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledCheck}
								onClick={() => saveData("perfil_puesto_id")}
							>
								<CheckIcon fontSize="inherit" />
							</IconButton>
						) : (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledEdit}
								onClick={() => enabledEdit("perfil_puesto_id")}
							>
								<EditIcon fontSize="inherit" />
							</IconButton>
						)}
					</Grid>
					<Grid
						item
						md={10}
						sx={{ mt: 1 }}
					>
						{editFields.jefe_directo_id ? (
							<Stack>
								<InputAutocomplete
									label="Jefe Directo"
									options={listColabo}
									value={jefe}
									setValue={setJefe}
									valueLabel="full_name"
								/>
							</Stack>
						) : (
							<TextField
								label="Jefe directo"
								variant="outlined"
								fullWidth
								disabled
								value={colaboradorData.jefe_directo}
							/>
						)}
					</Grid>
					<Grid
						item
						md={2}
						sx={{ p: 1 }}
					>
						{editFields.jefe_directo_id ? (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledCheck}
								onClick={() => saveData("jefe_directo_id")}
							>
								<CheckIcon fontSize="inherit" />
							</IconButton>
						) : (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledEdit}
								onClick={() => enabledEdit("jefe_directo_id")}
							>
								<EditIcon fontSize="inherit" />
							</IconButton>
						)}
					</Grid>
					<Grid
						item
						md={10}
						sx={{ mt: 1 }}
					>
						{editFields.sucursal_id ? (
							<GridInputSelect
								label={"Sucursal"}
								value={sucursal}
								setValue={setSucursal}
								options={listSucursales}
								size={12}
							/>
						) : (
							<TextField
								label="Sucursal"
								variant="outlined"
								fullWidth
								disabled
								value={colaboradorData.sucursal}
							/>
						)}
					</Grid>
					<Grid
						item
						md={2}
						sx={{ p: 1 }}
					>
						{editFields.sucursal_id ? (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledCheck}
								onClick={() => saveData("sucursal_id")}
							>
								<CheckIcon fontSize="inherit" />
							</IconButton>
						) : (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledEdit}
								onClick={() => enabledEdit("sucursal_id")}
							>
								<EditIcon fontSize="inherit" />
							</IconButton>
						)}
					</Grid>
					<Grid
						item
						md={10}
						sx={{ mt: 1 }}
					>
						<TextField
							label="Teléfono lubtrac"
							variant="outlined"
							type="number"
							fullWidth
							disabled={!editFields.telefono_lubtrac}
							value={tel}
							onChange={(e) => setTel(e.target.value)}
						/>
					</Grid>
					<Grid
						item
						md={2}
						sx={{ p: 1 }}
					>
						{editFields.telefono_lubtrac ? (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledCheck}
								onClick={() => saveData("telefono_lubtrac")}
							>
								<CheckIcon fontSize="inherit" />
							</IconButton>
						) : (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledEdit}
								onClick={() => enabledEdit("telefono_lubtrac")}
							>
								<EditIcon fontSize="inherit" />
							</IconButton>
						)}
					</Grid>
					<Grid
						item
						md={10}
						sx={{ mt: 1 }}
					>
						<TextField
							label="Correo electrónico"
							variant="outlined"
							fullWidth
							disabled={!editFields.correo}
							value={correo}
							onChange={(e) => setCorreo(e.target.value)}
						/>
					</Grid>
					<Grid
						item
						md={2}
						sx={{ p: 1 }}
					>
						{editFields.correo ? (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledCheck}
								onClick={() => saveData("correo")}
							>
								<CheckIcon fontSize="inherit" />
							</IconButton>
						) : (
							<IconButton
								aria-label="delete"
								size="large"
								color="success"
								disabled={disabledEdit}
								onClick={() => enabledEdit("correo")}
							>
								<EditIcon fontSize="inherit" />
							</IconButton>
						)}
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalDatosGenerales
