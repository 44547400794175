import { useState } from "react"

// Card Component
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
//icons
import PersonOffIcon from "@mui/icons-material/PersonOff"
// componentes
import FondoFijoFormulario from "Components/FondoFijo/formulario/FondoFijoFormulario"
import DialogSolicitudBaja from "../DialogSolicitudBaja"
import { TextCampoValor } from "#common/text/CampoValor"
import { formatMoney } from "#common/Funciones"
import ButtonIcon from "#common/buttons/ButtonIcon"
import EditIcon from "@mui/icons-material/Edit"

export const CardColaborador = ({ colaborador, setActualizar, reload }) => {
	const {
		area,
		photo,
		sucursal,
		fondo_fijo,
		perfil_puesto,
		nombre_completo,
		telefono_lubtrac: celular,
		fondo_in_progress,
		baja_in_progress,
		promotion_in_progress,
		no_empleado,
	} = colaborador

	const [openFondo, setOpenFondo] = useState(false)
	const [openSolicitudBaja, setOpenSolicitudBaja] = useState(false)
	const fields = {
		"No. Empleado": no_empleado,
		"Perfil Puesto": perfil_puesto,
		Departamento: area,
		Sucursal: sucursal,
		// "Fondo Fijo": fondo_fijo,
		"Teléfono Lubtrac": celular,
	}

	let fields_view = []
	for (const property in fields) {
		fields_view.push(
			<TextCampoValor
				key={property}
				field={property}
				value={fields[property]}
				color="text.secondary"
			/>,
		)
	}

	return (
		<Card
			sx={{
				minWidth: 400,
				width: "100%",
				height: "100%",
				display: "flex",
			}}
		>
			<CardMedia
				component="img"
				sx={{ width: 100 }}
				image={photo}
				alt="Colaborador"
				onError={(e) => {
					e.target.onerror = null
					e.target.src =
						"https://imagenespublicas.s3.us-east-2.amazonaws.com/usuario.webp"
				}}
			/>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					width: "100%",
					height: "100%",
					justifyContent: "space-between", // Este estilo asegura que el contenido esté alineado
				}}
			>
				<Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
					<CardContent>
						<Typography
							gutterBottom
							variant="h6"
							component="div"
							color="text.secondary"
						>
							{nombre_completo}
						</Typography>

						{fields_view}
						{promotion_in_progress && (
							<Typography
								whiteSpace="pre-line"
								variant="body1"
								color="text.secondary"
							>
								<strong>{`Se encuentra en proceso de promoción`}</strong>
							</Typography>
						)}

						<FondoFijoView
							fondo_fijo={fondo_fijo}
							fondo_in_progress={fondo_in_progress}
							setOpenFondo={setOpenFondo}
						/>
					</CardContent>
				</Box>
				<CardActions>
					<Stack
						direction={"row"}
						spacing={2}
					>
						<ButtonIcon
							disabled={baja_in_progress}
							title="Dar de baja"
							icon={<PersonOffIcon />}
							size="small"
							color="primary"
							onClick={() => setOpenSolicitudBaja(true)}
						/>
					</Stack>
				</CardActions>
				<FondoFijoFormulario
					open={openFondo}
					setOpen={setOpenFondo}
					actualizar={() => setActualizar((actualizar) => !actualizar)}
					colaborador={colaborador.id || null}
				/>
			</Box>
			<DialogSolicitudBaja
				open={openSolicitudBaja}
				setOpen={setOpenSolicitudBaja}
				colaborador={colaborador}
				reload={reload}
			/>
		</Card>
	)
}

const FondoFijoView = ({ fondo_fijo, fondo_in_progress, setOpenFondo }) => {
	// if (fondo_fijo == null) return null
	let buttonText = fondo_in_progress ? "fondo fijo en proceso " : "Solicitar Fondo Fijo"

	if (fondo_fijo == null)
		return (
			<Box>
				<Button
					size="small"
					disabled={fondo_in_progress}
					onClick={() => setOpenFondo(true)}
				>
					{buttonText}
				</Button>
			</Box>
		)
	else
		return (
			<Stack
				direction={"row"}
				spacing={1}
			>
				<TextCampoValor
					field="Fondo fijo"
					value={formatMoney(fondo_fijo)}
					color="text.secondary"
				/>
				<Button
					size="small"
					sx={{ height: 20 }}
					variant="outlined"
					disabled={fondo_in_progress}
					onClick={() => setOpenFondo(true)}
				>
					Editar
				</Button>
			</Stack>
		)
}
