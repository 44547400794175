import { Fragment, useMemo, useState } from "react"
// #
import { HasPermission } from "#common/Funciones"
import { COLOR_PRIMARY_TEXT } from "#constants/Styles"

//Componentes
import UseVacation from "./UseVacation"
import DialogVacation from "./VacationDialogRequest"

//material
import AddCircleIcon from "@mui/icons-material/AddCircle"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Skeleton from "@mui/material/Skeleton"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import MUIDataTable from "mui-datatables"

const SOLICITADA = 1
const APPROVE = 2

export default function VacationDetail({ colaborador }) {
	const [actualizar, setActualizar] = useState(false)
	const update = () => setActualizar(!actualizar)
	const params = useMemo(() => {
		return { params: { colaborador } }
	}, [colaborador])
	const { vacation, loading } = UseVacation({}, params, true, actualizar)
	const is_rh = HasPermission("RH")
	if (loading) return <VacationSkeleton />
	let disabled = false
	if (!is_rh) {
		const is_inprogress = (request) => {
			return [SOLICITADA, APPROVE].includes(request?.status_id)
		}
		let inprogress = vacation?.request.find(is_inprogress)

		disabled = vacation?.all_remaining < 1 || inprogress
	}

	return (
		<Box
			sx={{
				background: COLOR_PRIMARY_TEXT,
				width: "100%",
				borderRadius: 5,
			}}
		>
			<Grid2
				container
				spacing={2}
				alignContent={"center"}
				alignItems={"flex-start"}
				justifyContent={"space-around"}
			>
				<Grid2
					md={2}
					lg={4}
				>
					<Stack
						spacing={2}
						justifyItems={"flex-start"}
						alignItems={"center"}
					>
						<InfoTop {...vacation} />
						<div style={{ width: 200 }}>
							<Periods {...vacation} />
						</div>
					</Stack>
				</Grid2>
				<Grid2
					md={8}
					lg={7}
				>
					<Tablet
						vacation={vacation}
						colaborador={colaborador}
						update={update}
						disabled={disabled}
						{...vacation}
					/>
				</Grid2>
			</Grid2>
		</Box>
	)
}

/**
 * @type {Card}
 */
export const CardInfo = ({ contenido = null, ...props }) => {
	return (
		<Card
			sx={{
				minWidth: 100,
				height: "100%",
			}}
			{...props}
		>
			<CardContent
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: "100%",
				}}
			>
				{contenido}
			</CardContent>
		</Card>
	)
}

function InfoTop({ all_remaining = 0, days_taken = 0, next_remaining = 0 }) {
	const is_rh = HasPermission("RH")
	let text = [
		{
			campo: "Dias Disponibles",
			contenido: all_remaining,
		},
		{
			campo: "Dias Disfrutados",
			contenido: days_taken,
		},
	]

	if (is_rh)
		text.splice(1, 0, {
			campo: "Dias Proporcionales",
			contenido: next_remaining,
		})

	return (
		<div
			style={{
				display: "flex",
				flexGrow: 1,
				"flex-wrap": "wrap",
				// minWidth: "420px",
				justifyContent: "center",
			}}
		>
			{text.map(({ campo, contenido }, index) => (
				<CardInfo
					key={"info" + index}
					sx={{ minWidth: 200, margin: "5px" }}
					contenido={
						<Typography
							sx={{
								variant: "h7",
								whiteSpace: "pre-line",
								textAlign: "center",
							}}
						>
							<strong>{campo}</strong>
							{"\n" + contenido}
						</Typography>
					}
				/>
			))}
		</div>
	)
}

function Periods({ periods = [] }) {
	return (
		<Stack
			borderRadius={5}
			spacing={1}
			width={"100%"}
		>
			<CardInfo
				key={"periodos"}
				contenido={
					<Stack>
						<Typography
							variant="h6"
							sx={{ textAlign: "center" }}
						>
							Periodos
						</Typography>

						{periods.map((periodo) => {
							const { date_start, date_end, days_remaining } = periodo
							return (
								<Typography key={date_start}>{`${
									date_start?.slice(0, 4) || " "
								} - ${
									date_end?.slice(0, 4) || " "
								} :  ${days_remaining} Dias`}</Typography>
							)
						})}
					</Stack>
				}
			/>
		</Stack>
	)
}
function Tablet({ colaborador, vacation, update, request, disabled }) {
	const [open, setOpen] = useState(false)

	const columns = [
		{
			name: "status__description",
			label: "Status",
			options: {
				filter: false,
				sort: false,
				empty: true,
			},
		},
		{
			name: "authorize_name",
			label: "Authorize",
			options: {
				filter: false,
				sort: false,
				empty: true,
			},
		},
		{
			name: "date_start",
			label: "Inicio",
			options: {
				filter: false,
				sort: false,
				empty: true,
			},
		},
		{
			name: "date_end",
			label: "Fin",
			options: {
				filter: true,
				sort: false,
			},
		},
		{
			name: "days",
			label: "Dias ",
			options: {
				filter: false,
				sort: false,
			},
		},
	]
	const customToolbar = () => (
		<Button
			sx={{ height: "100%" }}
			variant="contained"
			size="small"
			//disabled={disabled}
			onClick={() => setOpen(true)}
			startIcon={<AddCircleIcon />}
		>
			solicitar
		</Button>
	)

	const options = {
		textLabels: {
			body: {
				noMatch: "No hay solicitudes",
			},
		},
		rowsPerPage: 100,
		search: true,
		filter: true,
		download: true,
		print: false,
		viewColumns: false,
		selectableRows: "none",
		tableBodyHeight: "40vh",
		customToolbar,
	}

	return (
		<Fragment>
			<MUIDataTable
				title={"Vacaciones Solicitadas"}
				data={request}
				columns={columns}
				options={options}
			/>
			<DialogVacation
				open={open}
				setOpen={setOpen}
				vacation={vacation}
				update={update}
				colaborador={colaborador}
			/>
		</Fragment>
	)
}

const VacationSkeleton = () => {
	return (
		<Grid2
			container
			spacing={2}
			padding={2}
			alignContent={"center"}
			justifyContent={"space-between"}
			alignItems={"center"}
		>
			<Grid2 xs={12}>
				<div>
					<Grid2
						spacing={2}
						container
						justifyContent={"space-between"}
					>
						<Grid2 xs={4}>
							<Stack
								spacing={1}
								justifyItems={"center"}
								alignItems={"center"}
							>
								<Stack
									spacing={2}
									direction={"row"}
								>
									<Skeleton sx={{ minWidth: "180px", height: 60 }} />
									<Skeleton sx={{ minWidth: "180px" }} />
								</Stack>
								<div style={{ width: 200 }}>
									<Skeleton sx={{ minWidth: "10px", height: 80 }} />
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</div>
							</Stack>
						</Grid2>
						<Grid2 xs={7}>
							<Skeleton sx={{ minWidth: "100px", height: 80 }} />
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
							<Skeleton />
						</Grid2>
					</Grid2>
				</div>
			</Grid2>
		</Grid2>
	)
}
