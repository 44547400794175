import { HasPermission } from "#common/Funciones"
import { Box, Grid, Tab, Tabs, Typography, createTheme } from "@mui/material/"
import { ChangeTitle } from "Components/menu/funciones"
import { VacanteProvider } from "Components/Vacantes/VacantesContext"
import PropTypes from "prop-types"
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { ActualizarProvider } from "../Components/Colaboradores/MiEquipo/ContextActualizar"
// import Promotion from "../Components/Colaboradores/MiEquipo/Promotion" // PromotionTable,
import DetalleVacante from "../Components/Vacantes/DetalleVacante"
import { LoadViewDetail, LoadViewTable } from "../Components/Vacantes/LoadViews"
import ModalNewVacante from "../Components/Vacantes/ModalNewVacante"
import TableVacantes from "../Components/Vacantes/TableVacantes"
import { PERFILES } from "../Constants/Strings"
import { getVacantes, vacanteGet } from "../Services/Api"
import PromotionTable from "Components/promotions/PromotionTable"
import PromotionDetails from "Components/promotions/PromotionDetails"
import DialogNewVacante from "Components/Vacantes/DialogNewVacante"
// import PromotionTable from "Components/Colaboradores/promotion/PromotionTable"
// import PromotionDetails from "Components/Colaboradores/promotion/PromotionDetails"

function TabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

const Vacantes = () => {
	ChangeTitle("Vacantes")
	const [openModal, setOpenModal] = useState(false)
	const [listVacantes, setListVacantes] = useState(null)
	const [dataVacante, setDataVacante] = useState(null)
	const [idVacante, setIdVacante] = useState(null)
	const [gridSize, setGridSize] = useState([10, 2])
	const [loadingList, setLoadingList] = useState(true)
	const [loadVac, setLoadVac] = useState(false)
	const [loadList, setLoadList] = useState(true)
	const [activeTab, setActiveTab] = useState(0)
	const [textTab, setTextTab] = useState("Vacantes ")
	const [promotionReload, setPromotionReload] = useState(true)
	const [vacantesActivas, setVacantesActivas] = useState(true)
	const reload = useSelector((state) => state.page.reloadVacante)
	const reloadTable = useSelector((state) => state.page.reloadTable)
	const token = useSelector((state) => state.user.token)
	const mdTheme = createTheme()
	let contentView = <></>
	let contentDetail = <></>
	const [promotion, setPromotion] = useState([0])

	useEffect(() => {
		setLoadingList(true)
		setListVacantes([])
		getVacantes(token, vacantesActivas)
			.then((result) => {
				setListVacantes(result.data)
			})
			.catch((error) => alert(error))
			.finally(() => setLoadingList(false))
	}, [token, loadList, reloadTable, vacantesActivas])

	useEffect(() => {
		setDataVacante(null)
		if (idVacante === 0 || idVacante == null) return
		setLoadVac(true)
		const success = (response) => {
			setDataVacante(response)
		}
		const end = () => {
			setLoadVac(false)
		}
		vacanteGet(idVacante, { success, end })
	}, [idVacante, reload])

	const openVacanteDetail = (rowData) => {
		if (idVacante !== 0 && idVacante === rowData[0]) {
			closeVacanteDetail()
			return
		}
		setTextTab("")
		setGridSize([3, 9])
		setIdVacante(rowData[0])
	}
	const openPromotionDetail = useCallback(
		(selectPromotion) => {
			if (promotion?.id === selectPromotion?.id || !selectPromotion) {
				closePromotionDetail()
			} else {
				setTextTab("")
				setGridSize([3, 9])
				setPromotion(selectPromotion)
			}
		},
		[promotion?.id],
	)

	const closePromotionDetail = () => {
		setPromotion([0])
		setTextTab("Vacante")
		setGridSize([10, 0])
	}
	const closeVacanteDetail = () => {
		setIdVacante(null)
		setTextTab("Vacante ")
		setGridSize([10, 2])
	}

	const handleChange = (event, newValue) => {
		setVacantesActivas(!vacantesActivas)
		setActiveTab(newValue)
		closeVacanteDetail()
	}

	if (loadingList) contentView = <LoadViewTable />
	else
		contentView = (
			<TableVacantes
				listVacantes={listVacantes}
				idVacante={idVacante}
				openVacanteDetail={openVacanteDetail}
				setOpenModal={setOpenModal}
				showDay={vacantesActivas}
			/>
		)

	if (loadVac) {
		contentDetail = <LoadViewDetail />
	} else if (dataVacante !== null)
		contentDetail = (
			<DetalleVacante
				dataVacante={dataVacante}
				days_passed={vacantesActivas}
			/>
		)

	if (activeTab === 2) {
		contentView = (
			<PromotionTable
				openVacanteDetail={openPromotionDetail}
				actualizar={promotionReload}
			/>
		)
		contentDetail =
			gridSize[1] > 5 ? (
				<PromotionDetails
					promotionId={promotion.id}
					reload={() => setPromotionReload(!promotionReload)}
				/>
			) : (
				[]
			)
	}

	let permisos = HasPermission([PERFILES.RH, PERFILES.GERENTE])
	return (
		<Grid
			container
			spacing={5}
		>
			<ActualizarProvider>
				<VacanteProvider>
					<Grid
						item
						md={gridSize[0]}
						align="center"
						style={{
							transition: mdTheme.transitions.create("all", {
								easing: mdTheme.transitions.easing.sharp,
								duration: mdTheme.transitions.duration.leavingScreen,
							}),
						}}
					>
						<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
							<Tabs
								value={activeTab}
								onChange={handleChange}
							>
								<Tab
									iconPosition="start"
									label={textTab + "Activas"}
									{...a11yProps(0)}
								/>
								<Tab
									iconPosition="start"
									label={textTab + "Cerradas"}
									{...a11yProps(1)}
								/>
								{permisos && (
									<Tab
										iconPosition="start"
										label={"Promociones"}
										{...a11yProps(2)}
									/>
								)}
							</Tabs>
						</Box>
						{contentView}
					</Grid>
					<Grid
						item
						md={gridSize[1]}
						style={{
							transition: mdTheme.transitions.create("all", {
								easing: mdTheme.transitions.easing.sharp,
								duration: mdTheme.transitions.duration.leavingScreen,
							}),
						}}
					>
						{contentDetail}
					</Grid>
					{/* <ModalNewVacante
						open={openModal}
						setOpen={setOpenModal}
						setLoadList={setLoadList}
						loadList={loadList}
					/> */}
					<DialogNewVacante
						open={openModal}
						setOpen={setOpenModal}
						reload={setLoadList}
					/>
				</VacanteProvider>
			</ActualizarProvider>
		</Grid>
	)
}

export default Vacantes
