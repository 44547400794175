import { useCallback, useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import dayjs from "dayjs"
//
import { promotionPut } from "#api/promotions"
import ButtonIcon from "#common/buttons/ButtonIcon"
import { MasterDialog } from "#common/Dialog/Dialog"
import { InputDate } from "#common/input/InputDate"
import ModalHistorial from "Components/Colaboradores/MiEquipo/ModalHistorial"
import { usePromotionsContext } from "./PromotionContext"
import { STATUS } from "./utils/constants"
// Material
import CancelIcon from "@mui/icons-material/Cancel"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import HistoryIcon from "@mui/icons-material/History"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

export function PromotionButtons({ promotion_id, disabled, statusId }) {
	const [open, setOpen] = useState(false)
	const [openConfirm, setOpenConfirm] = useState(false)

	const hidden = useMemo(() => {
		return statusId !== STATUS.En_Proceso
	}, [statusId])
	const hiddenReject = useMemo(() => {
		return statusId === STATUS.Finalizada || statusId === STATUS.Rechazada
	}, [statusId])

	const ButtonsIcons = useMemo(() => {
		const buttonsList = [
			{
				title: "Aprobar",
				color: "success",
				hidden,
				icon: <CheckCircleIcon fontSize="inherit" />,
				onClick: () => {
					setOpenConfirm({ open: true, response: true })
				},
			},
			{
				title: "Rechazar",
				color: "error",
				hidden: hiddenReject,
				icon: <CancelIcon fontSize="inherit" />,
				onClick: () => {
					setOpenConfirm({ open: true, response: false })
				},
			},
			{
				title: "Historial",
				color: "primary",
				icon: <HistoryIcon fontSize="inherit" />,
				onClick: () => {
					setOpen(true)
				},
			},
		]
		return buttonsList.map((item) => (
			<ButtonIcon
				key={item.title}
				size="large"
				{...item}
			/>
		))
	}, [hidden, hiddenReject])

	return (
		<Box sx={{ display: "flex", justifyContent: "right", paddingX: 3 }}>
			<Stack direction={"row"}>{ButtonsIcons}</Stack>
			<ModalHistorial
				setOpen={setOpen}
				open={open}
				promotion={promotion_id}
			/>

			<DialogConfirm
				{...{
					open: openConfirm?.open,
					setOpen: setOpenConfirm,
					response: openConfirm.response,
				}}
			/>
		</Box>
	)
}

const DialogConfirm = ({ response, open, setOpen }) => {
	const { reload, promotionId } = usePromotionsContext()
	const title = response ? "Aprobar promoción" : "Rechazar promoción"
	const [comentario, setComentario] = useState("")
	const [apply_date, setApply_date] = useState(null)
	const [disabled, setDisabled] = useState(false)
	let sendResponse = useCallback(() => {
		let data = {}
		if (!response)
			if (comentario.trim() === "") {
				toast.error("Se necesita un comentario", {
					toastId: "error_comentario",
				})
				return null
			} else {
				data["comentario"] = comentario.trim()
			}
		if (response)
			if (!apply_date?.isValid()) {
				toast.error("Se necesita una fecha valida", {
					toastId: "error_Fecha",
				})
				return null
			} else {
				data["apply_date"] = apply_date?.$d
			}

		data["status"] = response ? STATUS.Aprobada_RH : STATUS.Rechazada

		const success = () => {
			setOpen(false)
			reload()
		}
		const end = () => {
			setDisabled(false)
		}
		setDisabled(true)
		promotionPut(promotionId, data, { success, end })
	}, [apply_date, comentario, promotionId, reload, response, setOpen])

	useEffect(() => {
		if (!apply_date?.isValid() && !comentario.trim()) {
			setDisabled(true)
		} else {
			setDisabled(false)
		}
	}, [apply_date, comentario])

	const buttons = useMemo(
		() => [{ label: "aceptar", onClick: sendResponse, disabled: disabled }],
		[disabled, sendResponse],
	)

	return (
		<MasterDialog
			{...{ title, open, setOpen }}
			buttons={buttons}
			closeDialog={() => {
				setComentario("")
				setApply_date(null)
			}}
		>
			<ResponseTrue {...{ response, apply_date, setApply_date }} />
			<ResponseFalse {...{ response, comentario, setComentario }} />
		</MasterDialog>
	)
}
const ResponseFalse = ({ response, comentario, setComentario }) => {
	if (response) return null
	return (
		<Box width={"100%"}>
			<Typography
				variant="h6"
				textAlign={"center"}
			>
				¿Estas seguro de cancelar el proceso de promoción?
			</Typography>
			<TextField
				label="Comentario *"
				onChange={(e) => setComentario(e.target.value)}
				autoComplete="off"
				multiline
				fullWidth
			/>
		</Box>
	)
}
const ResponseTrue = ({ response, apply_date, setApply_date }) => {
	if (!response) return null

	let now = dayjs()
	let alertText = ""
	if (apply_date?.isValid() && apply_date <= now) {
		alertText =
			"Advertencia la promoción se efectuara de manera inmediata cuando se aprueben todas los movimientos"
	}
	return (
		<Box width={"100%"}>
			<Stack spacing={2}>
				<Typography
					width={"100%"}
					variant="h6"
					textAlign={"center"}
					sx={{ width: "100%" }}
				>
					¿Estas seguro de aprobar el proceso de promoción?
				</Typography>

				<InputDate
					sx={{ width: "100%" }}
					label="Fecha para efectuar el proceso de baja"
					value={apply_date}
					onChange={setApply_date}
				/>
				<Typography
					color={"red"}
					align="center"
				>
					{alertText}
				</Typography>
			</Stack>
		</Box>
	)
}
